import 'core-js/stable';
import 'regenerator-runtime/runtime';
import gsap from 'gsap';
import isMobile from 'ismobilejs';
import toggleFn from './functions/toggle';
import setCookie from './functions/cookies';

window.addEventListener('DOMContentLoaded', () => {
    paneHandle();
    dealsColumns();
    buttonBATFn();
    scrollTo();
    videoHandle();
    filterHandle();
    searchHandle();
    menuHandle();
    changeRedirectConnect();
    formHomeHandle();

    if (!isMobile().phone) {
        selectCustom();
    }
    else {
        gsap.set('fieldset', {
            opacity: 1,
        });
    }

    toggleFn();

    adContainerHeight();

    const postToEdit = document.querySelectorAll('.acf-form-fields .acf-field:not(.acf-field-post-to-edit)');
    Object.values(postToEdit).map(item => item.remove());
});

window.addEventListener('resize', () => {
    adContainerHeight();
});
// Your scripts here

const adContainerHeight = () => {
    const aside = document.querySelector('.main--aside');
    if (aside) {
        const closest = aside.closest('.container');

        gsap.set(aside, {
            right: closest.getBoundingClientRect().left,
            opacity: 1,
        });
    }
};

const dealsColumns = () => {
    const table = document.querySelector('.table--deals');
    if (table) {
        const col = table.querySelectorAll('tbody tr:first-child td');
        const colgroupItem = table.querySelectorAll('colgroup col');

        Object.values(col).map((item, index) => {
            const width = item.getBoundingClientRect().width;

            colgroupItem[index].width = width;
        });
    }
};

const buttonBATFn = () => {
    const buttons = document.querySelectorAll('.button--bat');

    Object.values(buttons).map(button => {
        button.addEventListener('click', async () => {
            if (confirm(`Message de confirmation`) === true) {
                button.classList.add('loading');

                const request = await fetch('/wp-json/bat/v1/send', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        company: button.dataset.company,
                    }),
                }).then(resp => resp.json()).then(resp => {
                    return resp;
                });

                const messagesEl = button.closest('.account--company').querySelector('.company--logs');
                request.log.map(item => {
                    const paragraph = document.createElement('P');
                    paragraph.innerText = item;
                    messagesEl.appendChild(paragraph);
                });

                if (request.type === 'error') {
                    messagesEl.classList.add('message--error');
                }
                else {
                    const alertBox = document.querySelector('.alert--impression');
                    button.setAttribute('disabled', true);
                    alertBox.innerText = `✅  Les informations de votre fiche société ${button.dataset.companyName} ont bien été transmises à l'imprimeur du guide papier. Merci !`;
                    alertBox.classList.remove('level-1');
                    alertBox.classList.remove('level-2');
                    alertBox.classList.add('alert--ok');

                    messagesEl.classList.remove('message--error');
                }

                if (request) {
                    button.classList.remove('loading');
                }
            }
        });
    });

};

const scrollTo = () => {
    const scrollToButton = document.querySelectorAll('[data-scrollto]');

    Object.values(scrollToButton).map(button =>
        button.addEventListener('click', () => {
            const element = document.getElementById(button.dataset.scrollto);

            element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }),
    );
};

const videoHandle = () => {
    const controls = document.querySelectorAll('.video-controls');

    Object.values(controls).map(control => {
        control.addEventListener('click', () => {
            const iframeWrapper = control.closest('.video--box').querySelector('.iframe--wrapper');
            console.log(iframeWrapper);
            const iframe = iframeWrapper.dataset.video;

            const parser = new DOMParser();
            const html = parser.parseFromString(iframe, 'text/html');
            const video = html.querySelector('body').children[0];
            video.src = video.src + '&autoplay=1';

            iframeWrapper.append(video);

        });
    });
};

const paneHandle = () => {
    const buttons = document.querySelectorAll('[data-pane]');

    for (const button of buttons) {
        button.addEventListener('click', () => {
            const id = button.dataset.pane;
            const elementsToToggle = document.querySelectorAll(`[data-pane-element=${id}]`);

            Object.values(document.querySelectorAll(`[data-pane=${id}]`)).map(item => item.classList.toggle('active'));
            Object.values(elementsToToggle).map(item => {
                item.classList.toggle('toggled');
            });

            if (button.dataset.popin) {
                const header = document.querySelector('.main-header');
                const footer = document.querySelector('.main-footer');
                const wfilter = document.querySelector('.wfilter');

                header.classList.toggle('blurred');
                footer.classList.toggle('blurred');
                wfilter.classList.toggle('whitecover');

            }
        });
    }
};

const filterHandle = () => {
        const form = document.querySelector('.form--filter');
        const noFilter = document.querySelector('.button--no-filter');

        if (form) {
            const childrenInputs = form.querySelectorAll('.filter--children .checkbox--wrapper input[type=checkbox]');
            const parentInputs = form.querySelectorAll('.filter--input > .checkbox--wrapper input[type=checkbox]');
            const inputs = [...parentInputs, ...childrenInputs];

            //Switch off/on checked input
            Object.values(childrenInputs).map(input => {
                input.addEventListener('change', () => {
                    const inputSiblingsChecked = input.closest('.filter--input').querySelectorAll('.filter--children input[type=checkbox]:checked');
                    const parentInput = input.closest('.filter--input').querySelector('* > .checkbox--wrapper input[type=checkbox]');

                    parentInput.checked = inputSiblingsChecked.length > 0;

                    const expertises = document.getElementById('filter--expertises');
                    const practices = document.getElementById('filter--practices');

                    if (expertises) {
                        if (parentInput.id === `filter-${expertises.dataset.catAssociated}`) {
                            expertises.style.display = parentInput.checked ? 'block' : 'none';
                        }
                    }

                    if (practices) {
                        if (parentInput.id === `filter-${practices.dataset.catAssociated}`) {
                            practices.style.display = parentInput.checked ? 'block' : 'none';
                        }
                    }
                });
            });
        
            //Switch off/on checked input
            Object.values(parentInputs).map(input => {
                input.addEventListener('change', () => {
                    const inputChildren = input.closest('.filter--input').querySelectorAll('.filter--children input[type=checkbox]');

                    if (!input.checked) {
                        Object.values(inputChildren).map(children => {
                            if (children.checked === true) {
                                children.click();
                            }
                        });
                    }
                    else {
                        Object.values(inputChildren).map(children => {
                            if (children.checked !== true) {
                                children.click();
                            }
                        });
                    }
                });
            });

            Object.values(childrenInputs).map(input => {
                input.addEventListener('change', () => {
                    const inputChildren = form.querySelectorAll('.filter--children input[type=checkbox]:checked');
                    const filtersArray = {
                        'secteurs': [], 'expertises': [], 'practices': [],
                    };

                    Object.values(inputChildren).map(el => {
                        filtersArray[el.dataset.tax].push(el.dataset.name);
                    });

                    const hiddenInput = document.querySelector(`input[hidden][name=${input.dataset.tax}]`);
                    hiddenInput.value = filtersArray[input.dataset.tax].length > 0 ? filtersArray[input.dataset.tax].join(',') : '';

                    if (filtersArray['secteurs'].length > 0 || filtersArray['expertises'].length > 0 || filtersArray['practices'].length > 0) {
                        noFilter.classList.add('visible');
                    }
                    else {
                        noFilter.classList.remove('visible');
                    }
                });
            });

            noFilter.addEventListener('click', e => {
                e.preventDefault();
                e.stopPropagation();

                const checked = document.querySelectorAll('.filter--children input[type=checkbox]:checked');
                Object.values(checked).map(input => input.click());
            });

            const searchCatList = document.querySelectorAll('.annuaire--filters--total .button--tag');

            Object.values(searchCatList).map((tag, index) => {
                tag.addEventListener('click', () => {
                    if (!tag.classList.contains('button--tag-keyword')) {
                        const hiddenInput = document.querySelector(`input[hidden][name=${tag.dataset.tax}]`);
                        const filtersArray = hiddenInput.value.split(',');
                        filtersArray.splice(filtersArray.findIndex(item => item === tag.dataset.term), 1);

                        console.log(filtersArray);

                        hiddenInput.value = filtersArray.join(',');
                    }
                    else {
                        const inputSearch = document.querySelector('input[name=q]');
                        inputSearch.value = '';
                    }

                    showLoader();
                    form.submit();
                });
            });

            form.addEventListener('submit', () => {
                showLoader();
            });
        }
        ;
    }
;

const searchHandle = () => {
    const button = document.querySelector('.button--search');
    const form = document.querySelector('.form--search');
    const input = form.querySelector('input');

    button.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();

        form.classList.toggle('toggled');
        if (form.classList.contains('toggled')) {
            input.focus();
        }
        else {
            input.blur();
        }
    });
};

const formHomeHandle = () => {
    const form = document.querySelector('.form--home');

    form?.addEventListener('submit', () => {
        showLoader();

        setCookie('cookie_filter_home', 'true', 7);
    });
};

const menuHandle = () => {
    const button = document.querySelector('.button--menu');
    const menu = document.querySelector('.main-menu--wrapper .navigation');

    button.addEventListener('click', () => {
        button.classList.toggle('active');
        menu.classList.toggle('open');
    });
};

const changeRedirectConnect = () => {
    const input = document.querySelector('input[name=redirect_to]');

    if (input) {
        input.value = '/mon-compte';
    }
};

const selectCustom = () => {
    const selects = document.querySelectorAll('[data-custom-select]');

    Object.values(selects).map((select, index) => {
        const _select = select.querySelector('select');
        const selectCustom = document.createElement('DIV');
        selectCustom.classList.add('custom-select--wrapper');
        selectCustom.setAttribute('data-toggle', 'custom-select-' + index);

        const selectLabel = document.createElement('SPAN');
        selectLabel.classList.add('custom-select--label');
        selectCustom.appendChild(selectLabel);

        const selectPane = document.createElement('DIV');
        selectPane.classList.add('custom-select--pane');
        selectPane.setAttribute('data-toggle-element', 'custom-select-' + index);

        const optionsListing = document.createElement('UL');

        const options = select.querySelectorAll('option');

        Object.values(options).map(option => {
            if (option.value !== '') {
                const li = document.createElement('LI');
                li.innerHTML = option.innerHTML.trimStart();
                li.dataset.value = option.value;

                if (option.dataset.optionParent === '') {
                    li.dataset.parent = true;
                }

                optionsListing.appendChild(li);

                li.addEventListener('click', () => {
                    const active = selectPane.querySelector('li.active');
                    if (active) {
                        active.classList.remove('active');
                    }

                    _select.value = li.dataset.value;
                    selectLabel.innerHTML = li.innerHTML;
                    li.classList.add('active');
                });
            }
            else {
                selectLabel.innerText = option.innerText;
            }
        });

        _select.style.display = 'none';
        _select.closest('fieldset').style.opacity = 1;

        selectPane.appendChild(optionsListing);
        selectCustom.appendChild(selectPane);
        select.appendChild(selectCustom);

        window.document.addEventListener('click', e => {
            if (!e.target.closest('[data-custom-select]')) {
                if (selectCustom.classList.contains('active')) {
                    selectCustom.click();
                }
            }
        });
    });
};

const showLoader = () => {
    const loaders = document.querySelector('.containload');
    const wfilter = document.querySelector('.wfilter');

    const elements_to_blur = document.querySelectorAll('.main-header, .main--footer, section');

    Object.values(elements_to_blur).map(item => item.classList.toggle('blurred'));
    wfilter.classList.toggle('whitecover');
    loaders.style.display = 'block';
};
