export default function toggleFn() {
    const buttons = document.querySelectorAll('[data-toggle]');

    for (const button of buttons) {
        button.addEventListener('click', () => {
            const id = button.dataset.toggle;
            const elementsToToggle = document.querySelectorAll(`[data-toggle-element=${id}]`);

            Object.values(document.querySelectorAll(`[data-toggle=${id}]`)).map(item => item.classList.toggle('active'));
            Object.values(elementsToToggle).map(item => {
                item.classList.toggle('toggled');
            });

            if (button.dataset.popin) {
                const header = document.querySelector('.main-header');
                const footer = document.querySelector('.main-footer');

                header.classList.toggle('blurred');
                footer.classList.toggle('blurred');
            }
        });
    }
};

